import * as Sentry from '@sentry/browser';
import { clientApi } from './clientApi';
import { serverSideApi } from './serverSideApi';

export async function requestPasswordResetCode(email) {
  const body = {
    email: email,
  };
  try {
    return await clientApi(
      '/profile/password/reset_request',
      'POST',
      null,
      JSON.stringify(body),
      null,
    );
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function validatePasswordResetCode(req, res, resetCode) {
  const body = JSON.stringify({ reset_code: resetCode });
  try {
    return await serverSideApi(req, res, '/profile/password/validate', 'POST', null, body, null);
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function resetPassword(resetCode, password) {
  const body = JSON.stringify({ reset_code: resetCode, password: password });
  try {
    return await clientApi('/profile/password/reset', 'POST', null, body, null);
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function getProfileData() {
  try {
    return await clientApi('/profile/info', 'GET');
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function getProfileAddresses() {
  try {
    return await clientApi(`/profile/address/get_profile_address/all`, 'GET');
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function updateBillingAddress(profileId, billingAddressData) {
  try {
    return await clientApi(
      `/profile/address/${profileId}?address_type=Billing`,
      'PUT',
      null,
      JSON.stringify(billingAddressData),
    );
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function updateAddress(profileId, addressData) {
  try {
    let response = await getProfileAddresses(profileId);

    if (response && response.status === 200) {
      if (Array.isArray(response.data) && response.data.length) {
        const existingAddress = response.data.find((address) => address.type === addressData.type);
        if (existingAddress) {
          return await clientApi(
            `/profile/address/${existingAddress.id}`,
            'PATCH',
            null,
            JSON.stringify(addressData),
          );
        }
      }
    }

    response = await clientApi('/profile/address', 'POST', null, JSON.stringify(addressData));

    if (response && response.status === 201) {
      return await clientApi(
        '/profile/address/add_address_to_profile',
        'POST',
        null,
        JSON.stringify({ profile_id: profileId, address_id: response.data.id }),
      );
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function deleteAddress(profileId) {
  try {
    return await clientApi(`/profile/address/${profileId}`, 'DELETE');
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function updateProfile(body) {
  try {
    return await clientApi(`/profile/update`, 'PATCH', null, JSON.stringify(body));
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function updatePassword(oldPassword, newPassword) {
  try {
    return await clientApi(
      `/profile/password/update`,
      'POST',
      null,
      JSON.stringify({
        old_password: oldPassword,
        new_password: newPassword,
      }),
    );
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function updatePreferences(profileId, body) {
  try {
    return await clientApi(
      `/profile/preference?profile_id=${profileId}&preference_type=newsletter`,
      'PUT',
      null,
      JSON.stringify(body),
      null,
    );
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function getPreferences(profileId) {
  try {
    return await clientApi(`/profile/preference/${profileId}?preference_type=newsletter`, 'GET');
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function deleteProfile(password) {
  try {
    return await clientApi('/profile/delete', 'DELETE', null, JSON.stringify({ password }));
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function logoutProfile() {
  try {
    return await clientApi('/api/logout/', 'GET', null, null, null, '');
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function refreshToken() {
  try {
    await clientApi('/api/refresh_token/', 'GET', null, null, null, '');
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function createProfile(payload, headers) {
  try {
    return await clientApi('/profile', 'POST', null, JSON.stringify(payload), headers);
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function fetchAddress(postCode, houseNumber) {
  try {
    return await clientApi(
      `/utilities/address?postcode=${postCode}&number=${houseNumber}`,
      'GET',
      null,
    );
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function getCurrentProfileSubscription() {
  try {
    return await clientApi('/ecommerce/orders/subscription/type', 'GET');
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function getCurrentProfileBundles(includeDefaultBundles = true) {
  try {
    return await clientApi('/license/profile_bundles', 'GET', {
      include_default_bundles: includeDefaultBundles,
    });
  } catch (error) {
    Sentry.captureException(error);
  }
}
